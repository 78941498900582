import "./Landing.css";
import { useRef, useState } from "react";

const serverIPs = [
    null,
    "45.235.98.68:27035"
]

const serverConnectCmd = [
    null,
    "steam://connect/45.235.98.68:27035",
    ""
]

function Landing() {
    const [state, setState] = useState({
        offset: [0,0],
        down: false,
        selectedItem: null
    });
    const [load, setLoad] = useState([]);
    var floatingWindow = useRef();
    var loadingScreen = useRef();
    var loadContainer = useRef();
    var items = [null, useRef(), useRef()];
    var bodyOptionButton = [useRef(), useRef(), useRef()];
    var onMouseDown = e => {
        setState({
            ...state,
            down: true,
            offset: [
                floatingWindow.current.offsetLeft - e.clientX,
                floatingWindow.current.offsetTop - e.clientY
            ]
        });
    }

    var onMouseUp = e => {
        setState({...state, down: false})
    }

    var onMouseMove = e => {
        e.preventDefault();
        if (state.down) {
            floatingWindow.current.style.left = (e.clientX + state.offset[0]) + 'px';
            floatingWindow.current.style.top  = (e.clientY + state.offset[1]) + 'px';
        }
    }

    var onClickItem = id => {
        return e => {
            if (state.selectedItem != null) {
                items[state.selectedItem].current.className = "floating-window-body-content-item"
            }
            setState({...state, selectedItem: id})
            items[id].current.className = "floating-window-body-content-item-selected"
            if (id === 1) {
                bodyOptionButton[0].current.className = "floating-window-body-option-allowed"
                bodyOptionButton[1].current.className = "floating-window-body-option-allowed"
                
                return;
            }
            
            bodyOptionButton[0].current.className = "floating-window-body-option"
            bodyOptionButton[1].current.className = "floating-window-body-option"
        }
    }

    var onMouseDownBodyOption = id => {
        return e => {
            if (state.selectedItem == null || state.selectedItem !== 1) return;
            
            bodyOptionButton[id].current.className = "floating-window-body-option-allowed-click"
        }
    }

    var onMouseUpBodyOption = id => {
        return e => {
            if (state.selectedItem == null || state.selectedItem !== 1) return;
            
            bodyOptionButton[id].current.className = "floating-window-body-option-allowed"
        }
    }

    var onClickBodyOption = e => {
        if (state.selectedItem == null || serverConnectCmd[state.selectedItem]?.length < 1) return;
        
        loadingScreen.current.style.display = "flex";
        var count = 1
        for (var i = 0; i < 10; i++) {
            setTimeout(() => {
                let el = document.createElement('div')
                setLoad([...load, el])
                loadContainer.current.appendChild(el)
            }, 125*count)
            count++
        }
        
        setTimeout(() => {
            window.open(serverConnectCmd[state.selectedItem], "_blank");
            loadingScreen.current.style.display = "none";
            while (loadContainer.current.firstChild) {
                loadContainer.current.removeChild(loadContainer.current.firstChild);
            }
            setLoad([])
        }, 1500)
    }

    var onClickCopyIP = e => {
        if (state.selectedItem == null || state.selectedItem !== 1) return;

        navigator.clipboard.writeText(serverIPs[state.selectedItem]);
        bodyOptionButton[2].current.style.display = "block";
        setTimeout(() => {
            bodyOptionButton[2].current.style.display = "none";
        }, 1500);
    }

    var onClickWindowQuit = e => {
        closeWindow()
    }

    var openWindow = () => {
        floatingWindow.current.style.display = "flex";
    }

    var closeWindow = () => {
        floatingWindow.current.style.display = "none";
    }

    var onClickOpenWindow = id => {
        return e => {
            switch (id) {
                case 1:
                    openWindow()
                    break;
                default:
                    return;
            }
        }
    }

    return (
        <div id="landing" onMouseMove={onMouseMove} onMouseUp={onMouseUp}>
            <div id="open-server-screen" ref={loadingScreen}>
                <div id="open-server-screen-loading">
                    <div id="open-server-screen-loading-div1">Loading</div>
                    <div id="open-server-screen-loading-div-spec">Connecting to the server</div>
                    <div id="open-server-screen-loading-div2">
                        <div id="open-server-screen-loading-div2-load" ref={loadContainer}>
                            <div />
                            <div />
                            <div />
                        </div>
                    </div>
                </div>
            </div>
            <div id="option-container">
                <div className="ag-item"><h1 className="ag-link" onClick={onClickOpenWindow(1)}>NUESTROS SERVIDORES</h1></div>
                <div className="ag-item"><h1 className="ag-link" onClick={onClickOpenWindow(1)}>INGRESAR</h1></div>
                <div className="ag-item"><h1 className="ag-link" onClick={onClickOpenWindow(1)}>EVENTOS</h1></div>
                <div className="ag-item"><h1 className="ag-link" onClick={onClickOpenWindow(1)}>SOBRE NOSOTROS</h1></div>
                <div className="ag">
                    <h1 className="ag-name">ANCESTRAL</h1>
                    <h1 className="ag-symbol">-</h1>
                    <h1 className="ag-name">GAMES</h1>
                </div>
            </div>

            <div ref={floatingWindow} id="floating-window">
                <div id="floating-window-top" onMouseDown={onMouseDown}>
                    <div>Servers</div>
                    <button id="quitWindowButton" onClick={onClickWindowQuit}>&#10006;</button>
                </div>

                <div id="floating-window-navbar">
                    <div className="floating-window-navbar-option">Internet</div>
                    <div className="floating-window-navbar-option-selected">Favorites</div>
                    <div className="floating-window-navbar-option">History</div>
                    <div className="floating-window-navbar-option">Spectate</div>
                    <div className="floating-window-navbar-option">Lan</div>
                    <div className="floating-window-navbar-option">Friends</div>
                </div>

                <div className="floating-window-body-separator" />

                <div className="floating-window-body-separator2">
                    <div className="floating-window-body-separator2-div1">Servers (1)</div>
                    <div className="floating-window-body-separator2-div2">Players</div>
                    <div className="floating-window-body-separator2-div3">Map</div>
                </div>

                <div id="floating-window-body-content">
                    <div ref={items[1]} className="floating-window-body-content-item" onClick={onClickItem(1)}>#1 Ancestral-Games [HNS BCM LVL]</div>
                    <div ref={items[2]} className="floating-window-body-content-item" onClick={onClickItem(2)}>#2 Ancestral-Games [HNS ESCALERAS]</div>
                </div>

                <div id="floating-window-body-options">
                    <div ref={bodyOptionButton[2]}className="floating-window-body-option-copied">&#10004; Copied!</div>

                    <div ref={bodyOptionButton[0]} className="floating-window-body-option" 
                    onMouseDown={onMouseDownBodyOption(0)} onMouseUp={onMouseUpBodyOption(0)}
                    onClick={onClickCopyIP}>Copy IP</div>

                    <div ref={bodyOptionButton[1]} className="floating-window-body-option" 
                    onMouseDown={onMouseDownBodyOption(1)} onMouseUp={onMouseUpBodyOption(1)}
                    onClick={onClickBodyOption}>Connect</div>
                </div>
            </div>
        </div>
    )
}

export default Landing;