import { Link } from "react-router-dom";
//import "./Home.css"

function Home() {
    return (
        <div>
            <p>You are at Home Page</p>
            <br />
            <Link to="/">Go to Landing Page</Link>
        </div>
    )
}

export default Home;